* {
  text-align: center;
  background-color: #181f30;
  padding: 5px;
  color: white;
}

body {
  margin: 0;
  font-family: Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {background-color: transparent;}
ul {list-style: none;}
a {color: #2d5c2e; text-decoration: none; font-weight: bold;}
a:hover {color: #F0F0F0; text-decoration: underline; font-weight: bold;}

.logo {
  width: 10%;
  min-width: 75px;
  height: auto;
  transition: 1s;
}
@media (prefers-reduced-motion: no-preference) {
  .logo {animation: logo-spin infinite 30s linear;}
}
@keyframes logo-spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
.button {
  width: 124px;
  background-color: #2d5c2e;
  border: none;
  box-shadow: 0px 0px 7px 0px #000000;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
/* @media screen and (max-width: 1350px) {
  .button {width: auto;}
} */

.button:hover {
  text-decoration: none;
  transform: scale(1.1);
  box-shadow: 0px 0px 16px 0px #FFFFFF;
}
.sidemenu {
  height: 100%;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #2d5c2e;
  overflow: hidden;
  padding-top: 20px;
}
.sidemenu a, .dropdown {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  border: none;
  background: #2d5c2e;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.sidemenu a:hover, .dropdown:hover {
  color: black;
}
.dropdown-bar {
  display: none;
  background-color: #1d3b1e;
  padding-left: 8px;
}
.active {
    background-color: #000000;
    border: #000000;
    color: #181f30;
}